<template>
  <b-row
      :class="{'mx-0': !displayComplement}"
  >
    <b-col
        :md="displayComplement?6:12"
        :class="{'px-0': !displayComplement}"
    >
      <b-form-group
          :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
          :label="displayLabel?capitalize($tc(name)):''"
          class="my-auto"
      >

        <validation-provider
            v-if="isRequired"
            #default="{ errors }"
            :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
            rules="required"
        >
          <vue-autosuggest
              ref="autocomplete"
              v-model="query"
              :suggestions="suggestions"
              :input-props="inputProps"
              :get-suggestion-value="getSuggestionValue"
              @input="onInputChange"
              @selected="onSelected"
          >
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{ suggestion.item._display }}</span>
            </template>
          </vue-autosuggest>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>


        <vue-autosuggest
            v-else
            ref="autocomplete"
            v-model="query"
            :suggestions="suggestions"
            :input-props="inputProps"
            :get-suggestion-value="getSuggestionValue"
            @input="onInputChange"
            @selected="onSelected"
        >
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item._display }}</span>
          </template>
        </vue-autosuggest>

      </b-form-group>
    </b-col>
    <b-col
        v-if="displayComplement"
        md="6"
    >
      <field-input
          :name="'complement'"
          :model.sync="localModel.complement"
      />
    </b-col>

  </b-row>


</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { VueAutosuggest }       from 'vue-autosuggest'
import { capitalize }           from '../../utils/filter'
import { ValidationProvider }   from 'vee-validate'

import i18n       from '@/libs/i18n'
import store      from '@/store'
import FieldInput from '@/components/input/Input'
import { isObject } from '../../utils/utils'

export default {
  components: {
    VueAutosuggest,
    FieldInput,
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: 'address'
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    listIndex: {
      type: Number,
      default: -1
    },
    cityOnly: {
      type: Boolean,
      default: false
    },
    displayComplement: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    const query = ref('')
    const results = ref([])
    const selected = ref(null)
    const inputProps = ref({
      id: 'address',
      placeholder: capitalize(i18n.tc('address')),
      class: 'form-control',
      name: 'ajax',
    })
    const suggestions = ref([])
    const timeout = ref(null)
    const debounceMilliseconds = ref(250)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, (val) => {
      emit('update:model', val)
    })

    watch(() => props.model, val => {
      if (isObject(props.model) && ('_display' in props.model)) {
        // if (val_display != localModel.value) {
        //
        // }
        // console.log("ok")
        localModel.value = val
        query.value = val._display
        // console.log(val._display)
      } else {
        if (val != localModel.value) {
          localModel.value = val
          query.value = val
        }
      }

    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const onInputChange = () => {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        // store.dispatch('address/getAddressesByQuery', query.value)
        store.dispatch('address/getAddressesByQuery', {
              addressString: query.value,
              cityOnly: props.cityOnly
            }
        )
            .then(addresses => {
              // console.log(response.data)
              // selected.value = null

              suggestions.value = [{ data: addresses }]
            })
      }, debounceMilliseconds.value)
    }

    const onSelected = (item) => {
      localModel.value = item.item

      if (props.displayComplement) {
        let complement = JSON.parse(JSON.stringify(localModel.value.complement))
        localModel.value.complement = complement
      }

      // console.log(item.item)
    }

    const getSuggestionValue = (suggestion) => {
      return suggestion.item._display
    }

    const updateField = (addressObject) => {
      query.value = addressObject._display
      localModel.value = addressObject
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      query,
      suggestions,
      inputProps,
      localModel,

      // Computed

      // Methods
      onInputChange,
      onSelected,
      getSuggestionValue,
      updateField
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
    // console.log(this.model)
    if (this.model != null) {
      this.query = this.model._display
    }

    // if (this.cityOnly && this.model != null) {
    //   this.query = this.model.city
    // this.query = this.model._display
    // } else if(this.model != null)
  },
  created () {
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-autosuggest.scss';
</style>