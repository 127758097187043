<template>
  <div>
    <l-marker
        :lat-lng="marker.latLon"
        :icon="icon"
        class=""
    >
      <l-popup>
        <h3 :style="'color: '+marker.color">{{ marker.user }}</h3>

        <div style="max-height: 400px" class="overflow-auto">
          <ul class="mt-2">
            <li
                v-for="workFlo in marker.workFlos"
            >
              <router-link
                  :to="{name: 'WorkFlo view', params: { workFloId: workFlo.id }}"
                  target="_blank"
                  class="d-flex cursor-pointer mb-50"
              >
                <div class="my-auto">{{ workFlo.title }}</div>
                <div class="my-auto ml-1">
                  <icon
                      icon="external-link-alt"
                      size="lg"
                  />
                </div>
              </router-link>
            </li>
          </ul>
        </div>

      </l-popup>
    </l-marker>
  </div>
</template>

<script>
import { ref, computed, watch }      from '@vue/composition-api'
import { LMarker, LPopup, LTooltip } from 'vue2-leaflet'
import { useRouter }                 from 'vue-router/composables'

export default {
  components: {
    LMarker,
    LPopup,
    LTooltip,
  },
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // console.log(props.marker)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const icon = computed(() => {
      return L.divIcon({
        // className: 'w-100 h-100',
        iconSize: [20, 20],
        html: createIcon()
      })
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const createIcon = () => {
      let div = document.createElement('div')
      div.classList.add('leaflet-marker-icon-number')

      let numdiv = document.createElement('div')
      numdiv.classList.add('number')
      numdiv.innerHTML = props.marker.number

      div.appendChild(numdiv)
      return div
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      icon,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    lang="scss"
>
.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-marker-icon-number {
}

.leaflet-marker-icon .number {
  position: relative;
  font-size: 12px;
  text-align: center;
}
</style>