<template>
  <div>
    <l-marker
        :lat-lng="marker.latLon"
        :icon="icon"
    >
      <l-tooltip>{{ company.name }}, {{ marker.address._display }}</l-tooltip>
      <l-popup>
        <b-badge
            pill
            :variant="'light-'+color"
            class="mb-50"
        >
          <b>{{ marker.workFlo._map_customer_type }}</b>
        </b-badge>
        <router-link
            :to="{name: 'WorkFlo view', params: { workFloId: marker.workFlo.id }}"
            target="_blank"
            class="d-flex cursor-pointer mb-50"
            v-b-hover="hoverHandler"
        >
          <h2 class="my-auto" :class="{'text-primary': isLinkHovered}">{{ marker.workFlo.title }}</h2>
          <div class="my-auto ml-1">
            <icon
                icon="external-link-alt"
                size="lg"
                :class="{'text-primary': isLinkHovered}"
            />
          </div>
        </router-link>

        <i>
          <b>{{ company.name }}</b>
          , {{ marker.address._display }}
        </i>
      </l-popup>
    </l-marker>
  </div>
</template>

<script>
import { ref, computed, watch }      from '@vue/composition-api'
import { LMarker, LPopup, LTooltip } from 'vue2-leaflet'
import { useRouter }                 from 'vue-router/composables'

export default {
  components: {
    LMarker,
    LPopup,
    LTooltip,
  },
  props: {
    displayByGroups: {
      tye: Boolean,
      default: false
    },
    marker: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // console.log(props.marker)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isLinkHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const company = computed(() => {
      if (props.marker.company == null) {
        return {
          name: '-'
        }
      } else {
        return props.marker.company
      }
    })

    const icon = computed(() => {
      return L.divIcon({ className: 'workFloMap_icon_' + props.marker.workFlo._map_customer_type })
    })

    const color = computed(() => {
      if (props.marker._map_customer_type == 'customer') {
        return 'success'
      } else if (props.marker._map_customer_type == 'leads') {
        return 'warning'
      } else {
        return 'danger'
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const hoverHandler = (isHovered) => {
      if (isHovered) {
        isLinkHovered.value = true
      } else {
        isLinkHovered.value = false
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isLinkHovered,

      // Computed
      company,
      icon,
      color,

      // Methods
      hoverHandler,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>